<template>
    <v-dialog v-model="internalOpen" fullscreen>
        <div style="background-color: #FFFFFF; padding: 0 1em 1em; position: relative; height: inherit;">
            <v-container style="position: relative;">
                <div class="py-4" style="width: 100%; background-color: inherit; position: sticky; top: 0; z-index: 10;">
                    <v-btn 
                        style="min-width: unset !important; min-height: unset !important; width: 32px !important; height: 32px !important;" 
                        class="px-4 py-4"
                        @click.stop="cerrarModal" 
                        text
                        rounded
                    >
                        <v-icon>mdi-window-close</v-icon>
                    </v-btn>
                </div>
                <div class="px-1 mt-1">
                    <v-form ref="form" @submit.prevent="handleSubmit">
                        <v-select
                            v-model="form.id_anio_fiscal"
                            label="Año fiscal"
                            outlined
                            :items="aniosFiscales"
                            :loading="aniosFiscalesCargando"
                            placeholder="Seleccione un año"
                            item-value="id"
                            item-text="anio"
                            clearable
                            :error-messages="id_anio_fiscalErrors"
                            @change="$v.form.id_anio_fiscal.$touch()"
                            @blur="$v.form.id_anio_fiscal.$touch()"
                        />
                        <v-file-input
                            v-model="form.adjunto"
                            label="PDF"
                            placeholder="Seleccione un PDF"
                            outlined
                            light
                            :error-messages="adjuntoErrors"
                            @change="$v.form.adjunto.$touch()"
                            @blur="$v.form.adjunto.$touch()"
                            accept="application/pdf"
                        />
    
                        <div class="my-4 d-flex flex-wrap flex-md-nowrap" style="gap: 16px;">
                            <v-btn 
                                @click.stop="cerrarModal" 
                                class="flex-grow-1 flex-shrink-1" 
                                color="secondary"
                            >
                                Cerrar
                            </v-btn>
                            <v-btn
                                type="submit"
                                class="flex-grow-1 flex-shrink-1"
                                color="primary"
                                :loading="guardandoDocumento"
                            >
                                Guardar
                            </v-btn>
                        </div>
                    </v-form>
                    <DataTableComponent
                        dense
                        noGutters
                        :headers="headers"
                        :items="documentos.data"
                        :show_loading="documentos.isLoading"
                        :total_registros="total"
                        v-models:pagina="paginaActual"
                        v-models:select="registrosPorPagina"
                        @paginar="manejarPaginacionRegistros"
                    >
                        <template v-slot:[`item.acciones`]="{ item }">
                            <div class="d-flex justify-center align-center" style="gap: 8px;">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn @click.stop="visualizarAdjunto(item)" v-bind="attrs" v-on="on" color="primary" icon>
                                            <v-icon>mdi-eye</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Ver adjunto</span>
                                </v-tooltip>
    
                                <v-tooltip v-if="!item.aprobado" top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn @click.stop="confirmarEliminarAdjunto(item)" v-bind="attrs" v-on="on" color="primary" icon>
                                            <v-icon>mdi-trash-can</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Eliminar adjunto</span>
                                </v-tooltip>
                            </div>
                        </template>
                    </DataTableComponent>
                    <PdfModal
                        :isOpen="modalVisualizacionAdjuntoAbierta" 
                        :source-loadable="visualizacionAdjunto" 
                        @on-visibility-change="manejarVisibilidadModalVisualizacionAdjunto"
                        filename="adjunto-anios-fiscal-pac"
                    />
                </div>
    
                <!-- confirmar guardado -->
                <ConfirmationModalComponent 
                    :is-open="confirmarGuardadoAbierta"
                    description="Desea guardar un nuevo documento"
                    :is-loading="guardandoDocumento"
                    @confirm="guardarAdjunto"
                    @cancel="confirmarGuardadoAbierta = false"
                />                
                <!-- confirmar eliminacion -->
                <ConfirmationModalComponent 
                    :is-open="confirmarEliminacionAbierta"
                    description="Desea eliminar el documento"
                    :is-loading="eliminandoDocumento"
                    @confirm="eliminarAdjunto"
                    @cancel="confirmarEliminacionAbierta = false"
                />
            </v-container>
        </div>
    </v-dialog>
</template>
<script>
import DataTableComponent from '@/components/DataTableComponent.vue';
import { Validator } from '@/utils/form-validation.js';
import { required } from 'vuelidate/lib/validators'
import PdfModal from '@/components/PdfModal.vue';
import { convertToFormData } from '@/utils/data';
import { ConfirmationModalComponent } from '@/components/utils';
import { 
    createLoadable,  
    setLoadableResponse,
    toggleLoadable,
    createPageable, 
    setPageableResponse, 
    togglePageable,
} from '@/utils/loadable';
import { isNil } from 'lodash';

const initialFormState = {
    id_anio_fiscal: null,
    adjunto: null,
};

export default {
    name: 'ModalCargaDocsAnioFiscalPac',
    emits: ['on-visibility-change', 'on-save', 'on-action'],
    components: { DataTableComponent, PdfModal, ConfirmationModalComponent },
    validations: {
        form: {
            id_anio_fiscal: { required },
            adjunto: { required },
        },
    },
    props: {
        isOpen: { type: Boolean },
        aniosFiscales: { type: Array },
        aniosFiscalesCargando: { type: Boolean },
    },
    data: () =>({
        internalOpen: false,
        form: {...initialFormState},
        headers: [
            { align: 'center', sortable: false, text: 'Año', value: 'AnioFiscal.anio' },
            { align: 'center', sortable: false, text: 'Acciones', value: 'acciones' },
        ],
        // utilidades
        documentoActivo: null,
        informacionGuardar: null,
        // Paginacion
        paginaActual: 1,
        registrosPorPagina: 10,
        // Guardado de documento
        guardandoDocumento: false,
        confirmarGuardadoAbierta: false,
        // eliminar documento
        eliminandoDocumento: false,
        confirmarEliminacionAbierta: false,
        // Ver adjunto
        modalVisualizacionAdjuntoAbierta: false,
        visualizacionAdjunto: createLoadable(null),
        //
        documentos: createPageable([], 10),
    }),
    computed: {
        id_anio_fiscalErrors() {
            return new Validator(this.$v.form.id_anio_fiscal).detect().getResult();
        },
        adjuntoErrors() {
            return new Validator(this.$v.form.adjunto).detect().getResult();
        },
        total() {
            return this.documentos.pagination.total_rows;
        },
    },
    methods: {
        cerrarModal() {
            this.clearForm();
            this.internalOpen = false;
        },
        clearForm() {
            this.$v.form.$reset();
            this.$refs.form.reset();
            this.$v.form.adjunto.$reset();
            this.form = initialFormState;
        },
        handleSubmit() {
            this.$v.$touch();

            if (this.$v.$invalid) return;
            this.informacionGuardar = { ...this.form };
            this.confirmarGuardadoAbierta = true;
        },
        // crud adjuntos
        async guardarAdjunto(data) {
            try {
                this.guardandoDocumento = true;
                const formData = convertToFormData(this.informacionGuardar);
                const { status } = await this.services.DocumentosAniosFiscalServices.guardarDocumento(formData);
                if ([200, 201].includes(status)) {
                    this.pushAppMessage({ type: 'success', message: 'Documento guardado correctamente.' });
                    this.clearForm();
                    this.cargarDocumentosAnioFiscalPac();
                    this.$emit('on-action');
                }
            } catch (error) {} 
            finally {
                this.guardandoDocumento = false;
                this.confirmarGuardadoAbierta = false;
            }
        },
        confirmarEliminarAdjunto(adjunto) {
            this.confirmarEliminacionAbierta = true;
            this.documentoActivo = adjunto;
        },
        async eliminarAdjunto() {
            try {
                this.eliminandoDocumento = true;
                const adjuntoId = this.documentoActivo.id;
                const { status } = await this.services.DocumentosAniosFiscalServices.eliminarDocumento(adjuntoId);
                if ([200, 201].includes(status)) {
                    this.pushAppMessage({ type: 'success', message: 'Documento eliminado correctamente.' });
                    this.clearForm();
                    this.cargarDocumentosAnioFiscalPac();
                    this.$emit('on-action');
                }
            } catch (error) {} 
            finally {
                this.eliminandoDocumento = false;
                this.confirmarEliminacionAbierta = false;
            }
        },
        manejarPaginacionRegistros(paginacion) {
            const { pagina, cantidad_por_pagina } = paginacion;
            this.paginaActual = pagina;
            this.registrosPorPagina = cantidad_por_pagina;
            this.cargarDocumentosAnioFiscalPac();
        },
        async visualizarAdjunto(adjunto) {
            this.modalVisualizacionAdjuntoAbierta = true;
            const idAdjunto = adjunto.id;
            toggleLoadable(this.visualizacionAdjunto);
            const { data } = await this.services.DocumentosAniosFiscalServices.visualizarAdjunto(idAdjunto);
            setLoadableResponse(this.visualizacionAdjunto, data, { isFile: true });
        },
        manejarVisibilidadModalVisualizacionAdjunto(visible) {
            this.modalVisualizacionAdjuntoAbierta = visible;
        },
        // Cargar documentos
        async cargarDocumentosAnioFiscalPac() {
            const filtros = {
                pagination: true,
                per_page: this.registrosPorPagina,
                page: this.paginaActual,
            };
            
            togglePageable(this.documentos);
            const { data, headers } = await this.services.DocumentosAniosFiscalServices.cargarDocumentos(filtros);
            setPageableResponse(this.documentos, data, headers);
        },
    },
    watch: {
        isOpen(value) {
            if (isNil(value)) return;

            this.internalOpen = value;
        },
        internalOpen(value) {
            this.$emit('on-visibility-change', value);
        },
    },
    created() {
        this.cargarDocumentosAnioFiscalPac();
    },
}
</script>